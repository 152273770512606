import React, { useEffect, useState } from "react";
import "./squad.scss";
import Banner from "../../components/Banner/Banner";
import {
  mbSquadBanner,
  squadBackImg,
  squadBanner,
  yellowTexture,
} from "../../images";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WhatsApp from "../../components/whatsApp/whatsApp";
import ContactUs from "../../components/ContactUs/ContactUs";
import { squadData, squadData2 } from "../../data/squadData";
import { useWindowSize } from "react-use";

const Squad = () => {
  const [activeTab, setActiveTab] = useState(1);
  const { width } = useWindowSize();

  const squadList = squadData.map((item, i) => (
    <div
      className="col-md-4 col-lg-3 squad_col col-sm-6"
      key={i}
      data-aos="fade-up"
    >
      <div className="content_box">
        <img src={squadBackImg} alt="white background" className="back_img" />
        <img
          src={item.img}
          alt={item.alt}
          className="squad_img"
          loading="lazy"
        />
        <h4 className="name">{item.name}</h4>
        <p className="desc">{item.desc}</p>
        <p className="pers_details">{item.details}</p>
      </div>
    </div>
  ));

  const squadList2 = squadData2.map((item, i) => (
    <div
      className="col-md-4 col-lg-3 squad_col col-sm-6"
      key={i}
      data-aos="fade-up"
    >
      <div className="content_box">
        <img src={squadBackImg} alt="white background" className="back_img" />
        <img
          src={item.img}
          alt={item.alt}
          className="squad_img"
          loading="lazy"
        />
        <h4 className="name">{item.name}</h4>
        <p className="desc">{item.desc}</p>
        <p className="pers_details">{item.details}</p>
      </div>
    </div>
  ));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // useEffect(() => {
  //   window.history.scrollRestoration = "manual";
  // }, []);

  return (
    <>
      <Header />
      <section className="squad_sec1">
        <Banner
          linkText="Squad"
          bannerImg={width > 767 ? squadBanner : mbSquadBanner}
          disable
        />
      </section>

      <section className="squad_sec2">
        <img
          src={yellowTexture}
          alt="yellow texture"
          className="yellow_back_img"
        />
        <div className="my_container tabs_wrapper">
          <button className={`${activeTab === 0 && "active"} tab_button`} onClick={() => setActiveTab(0)}>Season 1</button>
          <button className={`${activeTab === 1 && "active"} tab_button`}  onClick={() => setActiveTab(1)}>Season 2</button>
        </div>

        {activeTab === 0 && (
          <div className="my_container">
            <div className="row squad_row" data-aos="fade-up">
              {squadList}
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div className="my_container">
            <div className="row squad_row" data-aos="fade-up">
              {squadList2}
            </div>
          </div>
        )}
      </section>

      <ContactUs />
      <WhatsApp />
      <Footer />
    </>
  );
};

export default Squad;
