import React from "react";
import "./footer.scss";
import {
  bellIcon,
  facebookIcon,
  instaIcon,
  isplLogo,
  linkedIn,
  locationIcon,
  mailIcon,
  twitterIcon,
  youtubeIcon,
} from "../../images";
import { useWindowSize } from "react-use";
import { homepageURL, mediaURL, singamsTvURL, privacyURL, termURL } from "../helpers/constant-words";
import { Link } from "react-router-dom";

const Footer = () => {
  const { width } = useWindowSize();
  const current_year = new Date();
  return (
    <>
      <footer className="footer_sec1">
        <div className="my_container">
          <div className="row footer_row">
            <div className="col-md-3 footer_col">
              <div className="logo_wrapper">
                <Link to={homepageURL}>
                  <img src={isplLogo} alt="logo" className="footer_logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-3 footer_col">
              <div className="info_wrapper">
                <h2 className="heading">MORE INFORMATION</h2>
                <div className="contact_wrapper">
                  <img src={mailIcon} alt="mail" className="mail_icon" />
                  <a
                    href="mailto:social@chennaisingams.com"
                    className="info_text"
                  >
                    social@chennaisingams.com
                  </a>
                </div>
                <div className="contact_wrapper">
                  <img
                    src={locationIcon}
                    alt="location"
                    className="location_icon"
                  />
                  <a
                    href="https://maps.app.goo.gl/9UMX3cvCYeBMTb3KA"
                    target="_blank"
                    rel="noreferrer"
                    className="info_text"
                  >
                    Saraswati Sports Complex, <br /> Bhoomi Park Rd, Malad,
                    Asmita <br />
                    Jyoti Housing Society, Malad West,
                    <br /> Mumbai, Maharashtra 400095
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 footer_col">
              <div className="info_wrapper getintouch_wrapper">
                <h2 className="heading">QUICK LINKS</h2>
                <div className="contact_wrapper">
                  <Link to={mediaURL} className="info_text">
                    Media
                  </Link>
                </div>
                <div className="contact_wrapper">
                  <Link to={singamsTvURL} className="info_text">
                    Singams TV
                  </Link>
                </div>
                <div className="contact_wrapper">
                   <Link to={privacyURL} className="info_text">
	             Privacy Policy
	           </Link>
	        </div>
                <div className="contact_wrapper">
                   <Link to={termURL} className="info_text">
	             Terms & Conditions
                   </Link>
	        </div>


              </div>
            </div>
            <div className="col-md-3 footer_col">
              <div className="getintouch_wrapper">
                <h2 className="heading">GET IN TOUCH</h2>
                <div className="social_wrapper">
                  <a
                    href="https://youtube.com/@ChennaiSingamsOfficial?si=twxZiWWtjCodgo1O"
                    target="_blank"
                    rel="noreferrer"
                    className="social_link"
                  >
                    <img
                      src={youtubeIcon}
                      alt="youtube"
                      className="social_icon"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/ChennaiSingamsOfficial"
                    target="_blank"
                    rel="noreferrer"
                    className="social_link"
                  >
                    <img
                      src={facebookIcon}
                      alt="facebook"
                      className="social_icon"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/chennai_singams?igsh=eGFrZHJxNW1jOWZs"
                    target="_blank"
                    rel="noreferrer"
                    className="social_link"
                  >
                    <img
                      src={instaIcon}
                      alt="instagram"
                      className="social_icon"
                    />
                  </a>
                  <a
                    href="https://twitter.com/csingams?s=21&t=YjL70wJmuC8wG7SGyfdLeA"
                    target="_blank"
                    rel="noreferrer"
                    className="social_link"
                  >
                    <img
                      src={twitterIcon}
                      alt="twitter"
                      className="social_icon"
                    />
                  </a>
                  <a
                    href="https://t.snapchat.com/vy78IYk4"
                    target="_blank"
                    rel="noreferrer"
                    className="social_link"
                  >
                    <img
                      src={bellIcon}
                      alt="snapchat"
                      className="social_icon"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/chennai-singams/"
                    target="_blank"
                    rel="noreferrer"
                    className="social_link"
                  >
                    <img
                      src={linkedIn}
                      alt="linkedIn"
                      className="social_icon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright_wrapper">
            <p className="copy_text">
              Copyright {current_year.getFullYear()}, Chennai Singams |{" "}
              <a
                href="https://www.togglehead.in/"
                target="_blank"
                rel="noreferrer"
                className="copyright_crafted"
              >
                Crafted by Togglehead
              </a>
            </p>
          </div>
        </div>
        {width > 767 ? <div className="yellow_strip"></div> : null}
      </footer>
    </>
  );
};

export default Footer;
