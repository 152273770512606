// routes name
export const homepageURL = "/";
export const fixtureURL = "/fixtures";
export const scoreURL = "/score";
export const squadURL = "/squad";
export const statsURL = "/stats";
export const mediaURL = "/media";
export const singamsTvURL = "/singams-tv";
export const privacyURL = "/Privacy";
export const termURL = "/Term";
