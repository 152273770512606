import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Term  = () => {
  const privacyInline = {
      margin: "160px auto",
      maxWidth:"1000px",
      padding:"0 40px"
    };

const privh2 = {
   color: '#000',
   lineHeight: '24px',
   padding: '10px 0',
}

const privh3 = {
  fontSize : '24px',
  lineHeight: '32px',
  margin:'15px 0'
}	

const paragraphStyle = { color: '#000', fontSize: '16px', margin: '10px 0' };
  return (
    <>
      <Header />

      <div style={privacyInline}>
	  <h1>Terms and Conditions</h1>
<p style={paragraphStyle}>Last updated: December 23, 2024</p>

<p style={paragraphStyle}>Welcome to the website of Chennai Singams (“we,” “us,” “our” or “Company”). By accessing or using our website, you agree to comply with and be bound by the following terms and conditions (“Terms”). Please read these Terms carefully before using our Service.</p>

<h2 style={privh2}>Interpretation and Definitions</h2>

<h3 style={privh3}>Interpretation</h3>
<p style={paragraphStyle}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

<h3 style={privh3}>Definitions</h3>
<p style={paragraphStyle}>For the purposes of these Terms and Conditions:</p>
<ul>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Chennai Singams, SanRaj Corporate Park, 4th Dimension, 4th Floor, Mind Space, Malad (west), Mumbai, 400064.</li>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>Service</strong> refers to the Website.</li>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. </li>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>Third Party Content</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>Website</strong> refers to www.chennaisingams.com</li>
	<li style={{ color: '#000', fontSize: '16px', margin: '5px 0' }}><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
</ul>

<h3 style={privh3}>Acknowledgment</h3>
<p style={paragraphStyle}>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
<p style={paragraphStyle}>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
<p style={paragraphStyle}>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
<p style={paragraphStyle}>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
<p style={paragraphStyle}>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

<h3 style={privh3}>Website User Restrictions</h3>
<p style={paragraphStyle}>All information and materials on this Website, including but not limited to, text, trademarks, logos, graphics, and images (the "Materials"), are the copyrighted works and other forms of intellectual property of Chennai Singams or third parties who have authorized use of the Materials on this Website. Unless otherwise provided in respect to specific areas or Materials on the Website, Chennai Singams grants You only the limited license to display the Materials on Your personal computer or personal wireless device, and to copy and download such Materials, provided that: (1) any copyright, trademark or other notice appearing on such Materials is also retained by, displayed, copied, or downloaded as part of the Materials, and is not removed or obscured, and (2) such display, copy, or download is used solely for your personal information. Such Materials may not be distributed, transmitted, displayed, or broadcast in any manner or for any purpose. You acknowledge and agree that You have no right to nor will you modify, edit, alter, or enhance any of the Materials in any manner. This limited right terminates automatically, without notice to You, if you breach any of the Terms. Upon termination of this limited right of display, You agree to immediately erase or otherwise destroy any downloaded and printed Materials. Except as expressly stated herein, You acknowledge that You have no legal right, title, or interest in or to the Materials other than a limited right of display.</p>

<h3 style={privh3}>Third Party Content</h3>
<p style={paragraphStyle}>Chennai Singams has no specific prior review process or editorial control over Third Party Content. Any opinions, advice, statements, services, offers, or other information or content expressed or made available by third parties, including information providers, members or any other user of this Website, are those of the respective parties and not necessarily those of Chennai Singams. Neither Chennai Singams nor any third-party provider of information guarantees the accuracy, completeness, or usefulness of any Third Party Content, nor its merchantability or fitness for any particular purpose.</p>

<h3 style={privh3}>Proprietary Materials Agreement</h3>
<p style={paragraphStyle}>You understand that all the Materials provided on this Website are the proprietary property of Chennai Singams and are protected by copyright, trade secret, and other applicable laws in the respective jurisdiction.</p>

<p style={paragraphStyle}>You have the limited right to display the Materials only on Your personal computer, and to copy and download the Materials provided that: (1) any copyright and trademark notices appearing on such Materials are also displayed, copied or downloaded as part of the Materials, and are not removed, moved, or obscured, and (2) such display, copy, or download is solely for Your personal informational use. This limited right to access, display, and use of the Website terminates automatically, without notice to You, if You breach any of these Terms.</p>

<p style={paragraphStyle}>You acknowledge and agree that You have no right to modify, edit, alter or enhance any of the Materials in any manner. The Materials may not be distributed, transmitted, or broadcast in any manner, for any commercial purpose unless expressly authorized to do so by Chennai Singams, in advance, and in writing.</p>

<p style={paragraphStyle}>You agree not to use the Materials in any way that would compromise the proprietary nature of the Materials. Without limiting all of the foregoing, You agree not to deliver the Materials themselves, either reproduced or modified, or anything derived from the Materials, either orally or in writing, as part of any seminar, training program, workshop, consulting, or similar business activity which You make available to Your clients or to others, except with the prior written permission of Chennai Singams.</p>

<p style={paragraphStyle}>Except as expressly stated herein, You acknowledge that You have no right, title, or interest of any kind on any legal basis in or to the Materials.</p>

<p style={paragraphStyle}>Upon termination of this limited license or usage, You agree to immediately destroy any downloaded and/or printed Materials that You may have in your possession or control.</p>

<h3 style={privh3}>Links to Other Websites</h3>
<p style={paragraphStyle}>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>

<p style={paragraphStyle}>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any Third Party Content. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit. </p>

<h3 style={privh3}>Termination </h3>
<p style={paragraphStyle}>Your access to Website will be subject to Chennai Singams’ authorization to access the Website. Chennai Singams reserves the right to terminate Your access to the Website at any time without notice, for any reason (including, but not limited to, if you violate these Terms) or for no reason. While Chennai Singams’ preferred course of action is to advise You of Your inappropriate behavior and recommend any necessary corrective action, Chennai Singams does not represent that it will nor is it required to either actively monitor such behavior or to provide such notice to You. Chennai Singams’ right to terminate Your access is in addition to all other legal or equitable remedies available to Chennai Singams hereunder, which rights are fully and expressly reserved by Chennai Singams. Upon termination of this Agreement for any reason, You shall cease to use or further access any Website’s content or services and be liable for any unauthorized attempts to do so via any means.</p>

<h3 style={privh3}>Limitation of Liability</h3>
<p style={paragraphStyle}>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>

<p style={paragraphStyle}>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>

<p style={paragraphStyle}>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

<h3 style={privh3}>"AS IS" and "AS AVAILABLE" Disclaimer</h3>

<p style={paragraphStyle}>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of me...</p>
      </div>
      <Footer />
    </>
  );
};

export default Term;
