import { bannerVideo1, images, mbBannerVideo1 } from "../images";

export const bannerData = [
  {
    id: 1,
    type: "video",
    video: bannerVideo1,
    mbVideo: mbBannerVideo1,
  },
  {
    id: 2,
    type: "image",
    img: images.banner1.image,
    mbImg: images.mbBanner1.image,
    alt: images.banner1.alt,
  },
  {
    id: 3,
    type: "image",
    img: images.banner2.image,
    mbImg: images.mbBanner2.image,
    alt: images.banner2.alt,
  },
];

export const founderData = [
  {
    id: 1,
    img: images.sandip.image,
    alt: images.sandip.alt,
    desc: "He holds a Bachelor’s degree in Commerce from Mumbai University. He is also a qualified Chartered Accountant and a member of the Institute of Chartered Accountants of India. He has over 20 years of experience in audit and accounts, business analysis, SAP configuration, and software system consulting.",
    name: "Mr. Sandip Gupta",
    desg: "Chairman & Non-Executive Director of Route Mobile",
  },
  {
    id: 2,
    img: images.rajdip.image,
    alt: images.rajdip.alt,
    desc: "He is a dynamic entrepreneur who founded and is leading one of the fastest growing global technology & Cloud Communications companies – Route Mobile Limited. He is among one of the youngest tech entrepreneurs globally. His leadership has led Route Mobile to become one of the top global Cloud Communications Company providing Communications Platform as a Service (CPaaS).",
    name: "Mr. Rajdip Gupta",
    desg: "Founder of Route Mobile Limited",
  },
  {
    id: 3,
    img: images.surya.image,
    alt: images.surya.alt,
    desc: "He primarily works in Tamil cinema where he is one of the highest paid actors. He has received numerous awards including two National Film Awards, six Filmfare Awards South, three Tamil Nadu State Film Awards and two South Indian International Movie Awards. Suriya has featured six times in the Celebrity 100 list of Forbes India, which takes into account the earnings of Indian celebrities.",
    name: "Mr. Suriya",
    desg: "Actor and Film Producer",
  },
];

export const galleryImage = [
  {
    id: 1,
    img: images.gallery_27.image,
    mbImg: images.galleryMobile_27.image,
    thumbImg: images.galleryThumb_27.image,
    alt: images.gallery_27.alt,
  },
  {
    id: 2,
    img: images.gallery_28.image,
    mbImg: images.galleryMobile_28.image,
    thumbImg: images.galleryThumb_28.image,
    alt: images.gallery_28.alt,
  },
  {
    id: 3,
    img: images.gallery_30.image,
    mbImg: images.galleryMobile_30.image,
    thumbImg: images.galleryThumb_30.image,
    alt: images.gallery_30.alt,
  },
  
  {
    id: 4,
    img: images.gallery_31.image,
    mbImg: images.galleryMobile_31.image,
    thumbImg: images.galleryThumb_31.image,
    alt: images.gallery_31.alt,
  },
  {
    id: 5,
    img: images.gallery_33.image,
    mbImg: images.galleryMobile_33.image,
    thumbImg: images.galleryThumb_33.image,
    alt: images.gallery_33.alt,
  },
  {
    id: 6,
    img: images.gallery_34.image,
    mbImg: images.galleryMobile_34.image,
    thumbImg: images.galleryThumb_34.image,
    alt: images.gallery_34.alt,
  },
  {
    id: 7,
    img: images.gallery_36.image,
    mbImg: images.galleryMobile_36.image,
    thumbImg: images.galleryThumb_36.image,
    alt: images.gallery_36.alt,
  },
  {
    id: 8,
    img: images.gallery_38.image,
    mbImg: images.galleryMobile_38.image,
    thumbImg: images.galleryThumb_38.image,
    alt: images.gallery_38.alt,
  },
  {
    id: 9,
    img: images.gallery_39.image,
    mbImg: images.galleryMobile_39.image,
    thumbImg: images.galleryThumb_39.image,
    alt: images.gallery_39.alt,
  },
  {
    id: 10,
    img: images.gallery_41.image,
    mbImg: images.galleryMobile_41.image,
    thumbImg: images.galleryThumb_41.image,
    alt: images.gallery_41.alt,
  },
  {
    id: 11,
    img: images.gallery_42.image,
    mbImg: images.galleryMobile_42.image,
    thumbImg: images.galleryThumb_42.image,
    alt: images.gallery_42.alt,
  },
  {
    id: 12,
    img: images.gallery_43.image,
    mbImg: images.galleryMobile_43.image,
    thumbImg: images.galleryThumb_43.image,
    alt: images.gallery_43.alt,
  },
  {
    id: 13,
    img: images.gallery_44.image,
    mbImg: images.galleryMobile_44.image,
    thumbImg: images.galleryThumb_44.image,
    alt: images.gallery_44.alt,
  },
  {
    id: 14,
    img: images.gallery_45.image,
    mbImg: images.galleryMobile_45.image,
    thumbImg: images.galleryThumb_45.image,
    alt: images.gallery_45.alt,
  },
  {
    id: 15,
    img: images.gallery_37.image,
    mbImg: images.galleryMobile_37.image,
    thumbImg: images.galleryThumb_37.image,
    alt: images.gallery_37.alt,
  },
  {
    id: 16,
    img: images.gallery_35.image,
    mbImg: images.galleryMobile_35.image,
    thumbImg: images.galleryThumb_35.image,
    alt: images.gallery_35.alt,
  },
  {
    id: 17,
    img: images.gallery_19.image,
    mbImg: images.galleryMobile_19.image,
    thumbImg: images.galleryThumb_19.image,
    alt: images.gallery_19.alt,
  },
  {
    id: 18,
    img: images.gallery_1.image,
    mbImg: images.galleryMobile_1.image,
    thumbImg: images.galleryThumb_1.image,
    alt: images.gallery_1.alt,
  },
  {
    id: 19,
    img: images.gallery_2.image,
    mbImg: images.galleryMobile_2.image,
    thumbImg: images.galleryThumb_2.image,
    alt: images.gallery_2.alt,
  },
  {
    id: 20,
    img: images.gallery_3.image,
    mbImg: images.galleryMobile_3.image,
    thumbImg: images.galleryThumb_3.image,
    alt: images.gallery_3.alt,
  },
  {
    id: 21,
    img: images.gallery_4.image,
    mbImg: images.galleryMobile_4.image,
    thumbImg: images.galleryThumb_4.image,
    alt: images.gallery_4.alt,
  },
  {
    id: 22,
    img: images.gallery_5.image,
    mbImg: images.galleryMobile_5.image,
    thumbImg: images.galleryThumb_5.image,
    alt: images.gallery_5.alt,
  },
  {
    id: 23,
    img: images.gallery_6.image,
    mbImg: images.galleryMobile_6.image,
    thumbImg: images.galleryThumb_6.image,
    alt: images.gallery_6.alt,
  },
  {
    id: 24,
    img: images.gallery_7.image,
    mbImg: images.galleryMobile_7.image,
    thumbImg: images.galleryThumb_7.image,
    alt: images.gallery_7.alt,
  },
  {
    id: 25,
    img: images.gallery_8.image,
    mbImg: images.galleryMobile_8.image,
    thumbImg: images.galleryThumb_8.image,
    alt: images.gallery_8.alt,
  },
  {
    id: 26,
    img: images.gallery_9.image,
    mbImg: images.galleryMobile_9.image,
    thumbImg: images.galleryThumb_9.image,
    alt: images.gallery_9.alt,
  },
  {
    id: 27,
    img: images.gallery_10.image,
    mbImg: images.galleryMobile_10.image,
    thumbImg: images.galleryThumb_10.image,
    alt: images.gallery_10.alt,
  },
  {
    id: 28,
    img: images.gallery_11.image,
    mbImg: images.galleryMobile_11.image,
    thumbImg: images.galleryThumb_11.image,
    alt: images.gallery_11.alt,
  },
  {
    id: 29,
    img: images.gallery_12.image,
    mbImg: images.galleryMobile_12.image,
    thumbImg: images.galleryThumb_12.image,
    alt: images.gallery_12.alt,
  },
  {
    id: 30,
    img: images.gallery_13.image,
    mbImg: images.galleryMobile_13.image,
    thumbImg: images.galleryThumb_13.image,
    alt: images.gallery_13.alt,
  },
  {
    id: 31,
    img: images.gallery_14.image,
    mbImg: images.galleryMobile_14.image,
    thumbImg: images.galleryThumb_14.image,
    alt: images.gallery_14.alt,
  },
  {
    id: 32,
    img: images.gallery_15.image,
    mbImg: images.galleryMobile_15.image,
    thumbImg: images.galleryThumb_15.image,
    alt: images.gallery_15.alt,
  },
  {
    id: 33,
    img: images.gallery_16.image,
    mbImg: images.galleryMobile_16.image,
    thumbImg: images.galleryThumb_16.image,
    alt: images.gallery_16.alt,
  },
  {
    id: 34,
    img: images.gallery_17.image,
    mbImg: images.galleryMobile_17.image,
    thumbImg: images.galleryThumb_17.image,
    alt: images.gallery_17.alt,
  },
  {
    id: 35,
    img: images.gallery_18.image,
    mbImg: images.galleryMobile_18.image,
    thumbImg: images.galleryThumb_18.image,
    alt: images.gallery_18.alt,
  },
 
  {
    id: 36,
    img: images.gallery_20.image,
    mbImg: images.galleryMobile_20.image,
    thumbImg: images.galleryThumb_20.image,
    alt: images.gallery_20.alt,
  },
  {
    id: 37,
    img: images.gallery_21.image,
    mbImg: images.galleryMobile_21.image,
    thumbImg: images.galleryThumb_21.image,
    alt: images.gallery_21.alt,
  },
  {
    id: 38,
    img: images.gallery_22.image,
    mbImg: images.galleryMobile_22.image,
    thumbImg: images.galleryThumb_22.image,
    alt: images.gallery_22.alt,
  },
  {
    id: 39,
    img: images.gallery_23.image,
    mbImg: images.galleryMobile_23.image,
    thumbImg: images.galleryThumb_23.image,
    alt: images.gallery_23.alt,
  },
  {
    id: 40,
    img: images.gallery_24.image,
    mbImg: images.galleryMobile_24.image,
    thumbImg: images.galleryThumb_24.image,
    alt: images.gallery_24.alt,
  },
  {
    id: 41,
    img: images.gallery_25.image,
    mbImg: images.galleryMobile_25.image,
    thumbImg: images.galleryThumb_25.image,
    alt: images.gallery_25.alt,
  },
  {
    id: 42,
    img: images.gallery_26.image,
    mbImg: images.galleryMobile_26.image,
    thumbImg: images.galleryThumb_26.image,
    alt: images.gallery_26.alt,
  },
  {
    id: 43,
    img: images.gallery_29.image,
    mbImg: images.galleryMobile_29.image,
    thumbImg: images.galleryThumb_29.image,
    alt: images.gallery_29.alt,
  },
  {
    id: 44,
    img: images.gallery_32.image,
    mbImg: images.galleryMobile_32.image,
    thumbImg: images.galleryThumb_32.image,
    alt: images.gallery_32.alt,
  },
  {
    id: 45,
    img: images.gallery_40.image,
    mbImg: images.galleryMobile_40.image,
    thumbImg: images.galleryThumb_40.image,
    alt: images.gallery_40.alt,
  },
];

export const jerseyLogos =[
  {
    id: 1,
    img: images.sponsor07.image,
    alt: images.sponsor07.alt,
  },
  {
    id: 2,
    img: images.sponsor02.image,
    alt: images.sponsor02.alt,
  },
  {
    id: 3,
    img: images.sponsor04.image,
    alt: images.sponsor04.alt,
  },
  {
    id: 4,
    img: images.sponsor03.image,
    alt: images.sponsor03.alt,
  },
  {
    id: 5,
    img: images.sponsor05.image,
    alt: images.sponsor05.alt,
  },
  // {
  //   id: 6,
  //   img: images.sponsor06.image,
  //   alt: images.sponsor06.alt,
  // },
  {
    id: 7,
    img: images.sponsor08.image,
    alt: images.sponsor08.alt,
  },
];


